@import url(https://fonts.googleapis.com/css2?family=Urbanist:wght@600;700&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #3a3238;
}

#loading {
  background: url(/static/media/loading-squirrel.48e9b990.gif) no-repeat center;
  height: 100vh;
  width: 200px;
  background-size: 200px;
  margin: auto;
}

.error {
  height: 80px;
  width: 100%;
  border: 1px solid rgb(45, 43, 24);
  line-height: 80px;
  margin-bottom: 25px;
  background: rgb(255, 238, 51);
  color: rgb(45, 43, 24);
}

.first {
  padding: 20px;
}

.logo {
  background: url(/static/media/waiting-squirrel.cfe93b4a.gif) center no-repeat;
  height: 300px;
  background-size: 500px;
  margin-bottom: 40px;
}

.first button, .first input {
  margin-bottom: 25px;
}

.App {
  font-family: "Urbanist", sans-serif;
  text-align: center;
  color: #3b643b;
  font-size: 20px;
}

.selected button {
  background: #3b643b;
}

.nuvarande button {
  height: calc(1vh * 70);
  height: calc(var(--vh, 1vh) * 70);
  font-size: 100px;
  font-weight: 700;
  line-height: calc(1vh * 70);
  line-height: calc(var(--vh, 1vh) * 70);
  background: #3b643b;
  position: fixed;
  left: 0;
  right: 0;
}

.joker-select ul {
  margin-bottom: 0;
}

.joker-select button {
  background: #679d5d;
}

button:disabled {
  opacity: 0.5;
}

.footer button:disabled {
  opacity: 1;
  background: #989197;
  color: #ddd;
}

ul {
  margin-bottom: 100px;
}

li {
  list-style: none;
}

button {
  width: 100%;
  height: 80px;
  border: none;
  background: #435e4c;
  font-family: "Urbanist", sans-serif;
  color: #ffffff;
  font-size: 20px;
}

.odd button {
  background: #324c3b;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer button {
  background: #3c6958;
  height: 100px;
  line-height: 100px;
}

.list {
  margin: 0px 10px;
}

.tagen {
  color: #555;
}

.mupp {
  width: 100%;
  line-height: 65px;
  color: #fff;
  background: #3a3238;
}

.mupp .list {
  display: inline-block;
}

.select {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: calc(1vh * 30);
  height: calc(var(--vh, 1vh) * 30);
  background: #3a3238;
}

.con {
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
}

.block50 {
  width: 50%;
  height: calc(1vh * (100 / 3));
  height: calc(var(--vh, 1vh) * (100 / 3));
  display: inline-block;
  float: left;
  background: #101010;
  color: #fff;
  text-align: center;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.large {
  display: block;
  width: 100%;
}

.small {
  display: block;
  font-size: 20px;
}

.bb {
  width: 25%;
  height: 125px;
  display: inline-block;
  float: left;
  background: #101010;
  color: #fff;
  text-align: center;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.asd {
  font-size: 40px;
  color: #101010;
}

.undo {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

input {
  height: 80px;
  width: 100%;
  border: none;
  font-family: "Urbanist", sans-serif;
  font-size: 20px;
  text-align: center;
}

.hunter {
  width: 90%;
}

.remove {
  width: 10%;
}

#list {
  text-align: left;
  padding: 20px;
  background: #3a3238;
}

#list li {
  border: none;
  font-size: 30px;
  margin-bottom: 10px;
  color: #fff;
}

.pass {
  width: 90px;
  display: inline-block;
}

.name {
}

.download {
  border-radius: 40px;
  width: 50%;
}

.floatis {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
}

#list {
  margin-bottom: 0;
}

#hest {
  margin-bottom: 180px;
}

.smally {
  color: #fff;
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
}

.bb-o {
  background: #1a1a1a; 
}

